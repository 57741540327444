<template>
    <router-view class="router-view" v-slot="{ Component }">
        <transition :name="transitionName">
            <component :is="Component"/>
        </transition>
    </router-view>
</template>

<script>
import {reactive, toRefs} from 'vue'
import {useRouter} from 'vue-router'

export default {
    setup() {
        const router = useRouter()
        const state = reactive({
            transitionName: 'slide-left'
        })
        router.beforeEach((to, from) => {
            if (to.meta.index > from.meta.index) {
                state.transitionName = 'slide-left' // 向左滑动
            } else if (to.meta.index < from.meta.index) {
                // 由次级到主级
                state.transitionName = 'slide-right'
            } else {
                state.transitionName = ''   // 同级无过渡效果
            }
        })

        return {
            ...toRefs(state)
        }
    }
}
</script>

<style lang="less">
*{
    word-break: break-all;
}
html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

.router-view {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
    transition: all 300ms;
}

//进入的左滑
.slide-left-enter-active {
    position: fixed; //防止滚动偏移
    top: 0;
    bottom: 0;
    opacity: 0.5;
    transform: translate3d(100%, 0, 0);
}

.slide-left-enter-to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.slide-left-leave-active {
    opacity: 1;
}

.slide-left-leave-to {
    opacity: 0;
}

//返回的右滑
.slide-right-leave-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.slide-right-leave-to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}

.slide-right-enter-active {
    opacity: 0;
}

.slide-right-enter-to {
    opacity: 1;
}


.van-badge--fixed {
    z-index: 1000;
}
</style>
